import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import YoutubeVideo from 'components/Motion_Detection/Setup/YoutubeVideoCardSetup';
import PrimaryBox from 'components/Motion_Detection/Setup/PrimaryBox';
import NavButtons from 'components/Motion_Detection/Setup/NavButtons';
import ForumBox from 'components/Motion_Detection/Setup/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Motion Detection",
  "path": "/Motion_Detection/Setup/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Setup the Motion Detection Function",
  "image": "./MD_SearchThumb_MDSetup_1080p.png",
  "social": "/images/Search/MD_SearchThumb_MDSetup_1080p.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_MD-Motion-Detection-Setup_white.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='Motion Detection' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Setup the Motion Detection Function' image='/images/Search/MD_SearchThumb_MDSetup_1080p.png' twitter='/images/Search/MD_SearchThumb_MDSetup_1080p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/Einrichten/' locationFR='/fr/Motion_Detection/Setup/' crumbLabel="Setup" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "motion-detection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#motion-detection",
        "aria-label": "motion detection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Motion Detection`}</h1>
    <h2 {...{
      "id": "setup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#setup",
        "aria-label": "setup permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setup`}</h2>
    <h2 {...{
      "id": "instar-1080p-camera-series",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instar-1080p-camera-series",
        "aria-label": "instar 1080p camera series permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR 1080p Camera Series`}</h2>
    <p>{`To activate the motion detection, open the web interface of your INSTAR HD IP camera and click on the menu `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Areas/"
      }}>{`Alarm / Areas`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/48b1f653e495f16275eb15f8ca39ca25/20785/Alarm_Areas_Setup_1080p.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADUUlEQVQ4y52RbUzUBRzH/5trtdZaq7XQOXsYGT4hwkmCDhEu7zCUgUcHyI0nQRB5OAJJ4i6h2liLNSJF1ENSCAabDwh3yBF3I26uodX9Dy5srLZ60YuyMAT5PxyfdveiV/Wm7/Z59fvuu+9vX6FpYp7GsXu8M3qPU/+B1T7Le//gx+rwY3F8T6NjjvqhWWqv+zgdvA2LCBP5LbjLz+A6cQ5X5QVc1TZc5ktM1HTjNl/C+fYVss/8Skrnbxzs+IkD7X4Mrbc5+uFVrJ8MoCgygUAAZRWWZQWh87l0ujYWYYsowbalHNu2KmxR1VyMNtMVXUVHbC2PnfwFoX4BwfwjQvFdhOwx1maeI6HgI1RZCgUuLMv8vriM0B2ZR29iJT1JNfToTnHloIWew1Z6jVb6jRYu5zYTZv8TwQ5ruhdZ0/oDTzZ7eLz8CzTV7aDKLCytoAYCqIqC0B+ewkjcEZwJ+bi1R5lKKWU6rRyvoQL/WxXM5pj5bGSSiuGvKehyYGztI9XSwdYjdSQX1aJIEgFVDbEiSQjjYbtwvqrj1stapiL03Ik8hDc6Hf/uTGbiDjEXb+CDY000VjXRUPoulSW1FGcfQ6/P5HB2HqurAZYlJdRQkWWEgnVRNGzXc/5AHW26Gtq0Rj7dn0fLG/nY0gq5bCjmY7MFS0UDpsIaDDnFpKaZ2BSj5c0MI7Is8dfDZRRVRQ4Grn8hAl2inpwsKwlJpezZm8q+lCyMOYVkGAppKSvDbcnA2Wam03KCkgITpafryD1pQpeVi6qoBBUcRgq+nKTZweYtGjasfYn928LRxu5kpyaByKg49uxKoj1vL2fzkrl6PBaxOY7h9zPoHyjD9rmR+kZzKOT+4hKKGkAJjmJKiST+9XhefFogZsNTJO4I5/mwdTzxzHo0sVHsS9awcetutm/ehD7mFTrSn+XC8dcw5uspMqUjyTKqLBOQZaSVFQS3y82Xkx5uDPQyOdKHZ3SAwcFBrg2NMuYa56ZzjBFHEDs3bg7jcQwwO97P+K0hbnvcsKryCFAAKdiQR/PAff6PHgBzDwL8Mf8z0p1v4OESgug4j9d9Da84g+j9Dp9PRBRFRJ8P378w4xNDHv+MD/tX0/S57zJ1fZjpi118O+Xhb20U3XRBOx5OAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/48b1f653e495f16275eb15f8ca39ca25/e4706/Alarm_Areas_Setup_1080p.avif 230w", "/en/static/48b1f653e495f16275eb15f8ca39ca25/d1af7/Alarm_Areas_Setup_1080p.avif 460w", "/en/static/48b1f653e495f16275eb15f8ca39ca25/7f308/Alarm_Areas_Setup_1080p.avif 920w", "/en/static/48b1f653e495f16275eb15f8ca39ca25/cf7c2/Alarm_Areas_Setup_1080p.avif 1307w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/48b1f653e495f16275eb15f8ca39ca25/a0b58/Alarm_Areas_Setup_1080p.webp 230w", "/en/static/48b1f653e495f16275eb15f8ca39ca25/bc10c/Alarm_Areas_Setup_1080p.webp 460w", "/en/static/48b1f653e495f16275eb15f8ca39ca25/966d8/Alarm_Areas_Setup_1080p.webp 920w", "/en/static/48b1f653e495f16275eb15f8ca39ca25/d7a6a/Alarm_Areas_Setup_1080p.webp 1307w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/48b1f653e495f16275eb15f8ca39ca25/81c8e/Alarm_Areas_Setup_1080p.png 230w", "/en/static/48b1f653e495f16275eb15f8ca39ca25/08a84/Alarm_Areas_Setup_1080p.png 460w", "/en/static/48b1f653e495f16275eb15f8ca39ca25/c0255/Alarm_Areas_Setup_1080p.png 920w", "/en/static/48b1f653e495f16275eb15f8ca39ca25/20785/Alarm_Areas_Setup_1080p.png 1307w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/48b1f653e495f16275eb15f8ca39ca25/c0255/Alarm_Areas_Setup_1080p.png",
              "alt": "Alarm Area Setup for the 1080p Camera Series",
              "title": "Alarm Area Setup for the 1080p Camera Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now that we set the motion detection areas, we can continue with adding actions, the camera has to take, in case of a detection event. Open the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Actions/"
      }}>{`Alarm / Actions`}</a>{` menu to configure the alarm actions.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "741px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/60684dcfd4a00f8feb5e7d17bf1bff46/13e20/Alarm_Actions_Setup_1080p.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACWklEQVQoz3VSTU/bQBDN7+wfoL+g5x4IEqUtSBW99lIVqYeGBhMnxKoBobaCotIvSgIBktAaO7HjJF7v7uzubLUbDFz6ZPmw8968ebNbWtnurHz883S393yv/2S3N+93y353cae3vNdf2u2V/d7CtvnK9rzsd+c/XC5/DlabrRfOjxLJMiWlklJbIJr/rzBb+xYe9CdaoxRCCbgpWEqajJI05QpLhBCllJQS0dSV5fwMyZujaN+KAYQQoGzVEjBN0ySOBUApyzIhBOOccY6Iyjrsd0fLO+dee6hR5ZQyxgBEMRqGYRgEAefciDnnjDFKKQDMGHEyap91omigtQZrre+BWiilzNhCCM45AMgiOSEkiqLpdKqUmolv+yLiMI6jwQAAjFhKOWNgsZUsy8IwmkwmiEgpzfP8VqwRs/FomiZSCDM2AFAbjNvYRjxOr/uX4yTWWksphRDy7jrwPCangywHaZwRUVpgYf13TA+6yWVCUMncJjR9bQkkPnLPHlZa7UF+tzDGmGmglNa6dhLPVVqvv4aGLcT9RBKxejx8+z2KMrgZmxUAu9hGK557d7J2FGqtGOOMAwdQaF6BknI8jEbRtYDiqmbbZ4wJaZyrx4MHa8evvgRa65xScbst+04mnKeMCaVuHgkvMHM+j3OvHf8Op2ZBSsWEX8TkIsnDEaGdTtY+I+1TkZESY0z/H7Okn67I6n748nDoHF4NF58F5aXg8YLo9ku+7zuOs16pOI6zWatt1ly3XncbDcet17eaW57X9Dy3tulU15sN12tubbiu02hs1N331eo/EiQ6sYR+6DYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/60684dcfd4a00f8feb5e7d17bf1bff46/e4706/Alarm_Actions_Setup_1080p.avif 230w", "/en/static/60684dcfd4a00f8feb5e7d17bf1bff46/d1af7/Alarm_Actions_Setup_1080p.avif 460w", "/en/static/60684dcfd4a00f8feb5e7d17bf1bff46/16c5f/Alarm_Actions_Setup_1080p.avif 741w"],
              "sizes": "(max-width: 741px) 100vw, 741px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/60684dcfd4a00f8feb5e7d17bf1bff46/a0b58/Alarm_Actions_Setup_1080p.webp 230w", "/en/static/60684dcfd4a00f8feb5e7d17bf1bff46/bc10c/Alarm_Actions_Setup_1080p.webp 460w", "/en/static/60684dcfd4a00f8feb5e7d17bf1bff46/7545b/Alarm_Actions_Setup_1080p.webp 741w"],
              "sizes": "(max-width: 741px) 100vw, 741px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/60684dcfd4a00f8feb5e7d17bf1bff46/81c8e/Alarm_Actions_Setup_1080p.png 230w", "/en/static/60684dcfd4a00f8feb5e7d17bf1bff46/08a84/Alarm_Actions_Setup_1080p.png 460w", "/en/static/60684dcfd4a00f8feb5e7d17bf1bff46/13e20/Alarm_Actions_Setup_1080p.png 741w"],
              "sizes": "(max-width: 741px) 100vw, 741px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/60684dcfd4a00f8feb5e7d17bf1bff46/13e20/Alarm_Actions_Setup_1080p.png",
              "alt": "Alarm Actions Setup for the 1080p Camera Series",
              "title": "Alarm Actions Setup for the 1080p Camera Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`To use the `}<strong parentName="li">{`email notification`}</strong>{`, you first have to set your email login credentials inside the `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/System/Email/"
        }}>{`System / Email`}</a>{`. Please follow our `}<a parentName="li" {...{
          "href": "/en/Motion_Detection/Alarm_Notification/"
        }}>{`Alarm Notification Guide`}</a>{`.`}</li>
      <li parentName="ul">{`All video and snapshot recordings to `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/Features/SD_Card/"
        }}>{`SD Card`}</a>{`, can be accessed by our `}<strong parentName="li">{`free app`}</strong>{` for `}<a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/Windows_Phone/Recording/"
        }}>{`Windows Phone`}</a>{`, `}<a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/Metro/Recording/"
        }}>{`Windows Metro`}</a>{`, `}<a parentName="li" {...{
          "href": "/en/Software/Android/InstarVision/Recording/"
        }}>{`Android`}</a>{`, `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPhone/Recording/"
        }}>{`iPhone`}</a>{` and `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPad/Recording/"
        }}>{`iPad`}</a>{`. Alternatively, you can `}<a parentName="li" {...{
          "href": "/en/Motion_Detection/SD_Card_Access/"
        }}>{`Download all files`}</a>{` to your computer.`}</li>
      <li parentName="ul">{`To use the FTP server upload, you first have to set your server information inside the `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/Network/FTP/"
        }}>{`Network / FTP`}</a>{` menu. You can, for example, use your `}<a parentName="li" {...{
          "href": "/en/Motion_Detection/Router_as_a_FTP_Server/"
        }}>{`Router or NAS as FTP Server`}</a>{`. Or run a `}<a parentName="li" {...{
          "href": "/en/Motion_Detection/FTP_Server_Setup/"
        }}>{`FTP Server on your Windows PC`}</a>{`.`}</li>
      <li parentName="ul">{`It is recommended to use the integrated Passive Infrared Sensor (`}<strong parentName="li">{`PIR`}</strong>{`) in combination (`}<strong parentName="li">{`Link with Areas`}</strong>{`) with your motion detection areas, to filter out false alerts caused by e.g. rain, snow or changes in the lighting condition. You can also deactivate the detection areas and use the PIR sensor by itself.`}</li>
    </ul>
    <YoutubeVideo mdxType="YoutubeVideo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      